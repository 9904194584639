export const APP_VERSION = '1.0.0';
export const COPYRIGHT = '����������Ƶ © 2022';
export const POWERED_BY = 'Powered by kynatech.ph';

// DEV CREDENTIALS


export const SERVER_URL = 'http://localhost:3000/'
export const AUTHORIZATION = 'Basic teddy:t#d@s#cuR3d'

export const SERVER_API_KEY = 'app-dev-api'
export const MOBILE_PAY_URL = SERVER_URL + 'q/mobilepay'

export const FBAPPID = '224258573080686';

// # CRYPTO JS
// export const DATA_SECRET_KEY = '3c14cda5ffdc56ca142e868908e760cb23e960e60a44ab43ff81f00dfade3610'
export const DATA_SECRET_KEY = 'a7a7f55f3a8defb5'

export const ABSOLUTE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://pmha.org.ph'


export const API_ERROR_INVALID_SCHEMA = 'Form validation error.';
export const ERROR_OCCURED_MSG = 'An error occurred. Please contact your system administrator.';
export const RESCHEDULE_LIMIT = 3;
export const DEFAULT_WHERE_ARR = {
    is_deleted: false,
}
